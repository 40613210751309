h3 {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
}
p {
  padding-left: 10px;
  padding-right: 10px;
}

h3.text-left, p.text-left {
  text-align: left;
  border-left: 5px solid black;
}

h3.text-right, p.text-right {
  text-align: right;
  border-right: 5px solid black;
}

@media (max-width: 768px) {
  h3, p {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .hide-text {
    display: none;
  }
}

@media (min-width: 769px) {
  p {
    display: block;
  }
}

.home-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: max-content;
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  .home-background {
    height: 860px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .home-background {
    height: 900px;
  }
}
.service-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: max-content;
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  .service-background {
    height: 1150px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .service-background {
    height: 1850px;
  }
}